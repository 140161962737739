import styles from "./SpecialTitle.module.scss";
import strings from "../../../../assets/localization/strings.jsx";


const SpecialTitle = (props) => {
    const { data, theme } = props
    const mainContainerClass = `${styles.mainContainer} ${styles[`${theme}Container`]} `

    return (
        <div className={mainContainerClass}>
            <div className={`${styles.specialTitleBox} flex-column`}>
                { data.preview && (
                    <span className={styles.specialPreview}>
                        {strings.paywall.greenTheme.specialTitle.preview[data.preview]}
                    </span>
                )}
                { data.title && (
                    <span className={styles.specialTitle}>
                        {strings.paywall.greenTheme.specialTitle.title[data.title]}
                    </span>
                )}
                { data.upText && (
                    <span className={styles.specialUpText}>
                        {strings.paywall.greenTheme.specialTitle.upText[data.upText]}
                    </span>
                )}
            </div>
        </div>
    )
}

export default SpecialTitle;