import styles from "./GetButton.module.scss";
import LazyImage from "../../lazyImage/LazyImage";

const GetButton = props => {
    const { nextPage, small, text = 'Get my program', style, themeData, withImage } = props;
    const buttonClass = `${styles.buttonContainer} ${styles[`${style}Campaign`]} ${styles[`${themeData?.pageContainer}Container`]} ${small ? styles.smallButton : ''}`

    return (
        <button className={buttonClass} onClick={nextPage}>
            { themeData?.paywallRateButtonImage && withImage && (
                <div className={styles.imageContainer} style={themeData.paywallRateButtonImage.styles}>
                    <LazyImage
                        src={themeData.paywallRateButtonImage.imageSrc}
                        srcWebp={themeData.paywallRateButtonImage.imageSrcWebp}
                        alt=""
                    />
                </div>
            )}
            <span className={styles.buttonText}>{text}</span>
            <span className={styles.pulseButtonRings}></span>
            <span className={styles.pulseButtonRings}></span>
            <span className={styles.pulseButtonRings}></span>
        </button>
    )
}   

export default GetButton;