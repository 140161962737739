import styles from "./PaywallBackground.module.scss";
import LazyImage from "../../lazyImage/LazyImage";

const PaywallBackground = props => {
    const { images } = props;

    return (
        <div className={styles.mainContainer}>
            { images.map(item => (
                <div key={item.id} className={styles.imageContainer} style={item.styles}>
                    <LazyImage
                        src={item.imageSrc}
                        srcWebp={item.imageSrcWebp}
                        alt=""
                    />
                </div>
            ))}
        </div>
    )
}

export default PaywallBackground;
