import styles from "./ButtonCard.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const ButtonCard = props => {
    const { card, selectCard, activeCard, cardStyles } = props;
    const divStyles = cardStyles?.divStyles
    let pStyles =  { ...cardStyles?.pStyles, fontSize: strings[card.section][card.page][card.localID].fontSize }

    return (
        <div
            className={`${styles.cardContainer} ${activeCard?.id === card.id ? styles.activeCard : ''} cardContainer`}
            style={divStyles}
            onClick={() => selectCard(card)}
        >
            <p
                className={`${styles.cardTitle}`}
                style={pStyles}
            >
                {strings[card.section][card.page][card.localID].text}
            </p>
        </div>
    )
}   

export default ButtonCard;