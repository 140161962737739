import styles from "./BackgroundImageBox.module.scss";
import LazyImage from "../lazyImage/LazyImage";

const BackgroundImageBox = props => {
    const { pageInfo, src } = props;

    return (
        <div className={styles.mainContainer}>
            <div className={styles.imageContainer} style={pageInfo.boxBackgroundStyle}>
                <div className={styles.imageBox} style={pageInfo.boxBackgroundStyle}>
                    <LazyImage
                        className={styles.backgroundImage}
                        style={pageInfo.imageBackgroundStyle}
                        srcWebp={src.backgroundImageWebp}
                        src={src.backgroundImage}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}   

export default BackgroundImageBox;
