import { useState, useEffect, MouseEvent } from "react";

import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { styled } from "@mui/material/styles";
import styles from "./PasswordInput.module.scss";

const CssFormControl = styled(FormControl)({
    '&': {
        border: '1px solid #BBBBBB',
        borderRadius: '8px',
        paddingRight: '10px',
    },
    '& label': {
        fontSize: '1.1rem',
        color: '#BBBBBB',
    },
    '& .MuiFilledInput-underline:before': {
        borderBottom: 'unset !important'
    },
    '& .MuiFilledInput-underline:after': {
        borderBottom: 'unset !important'
    },
    '.MuiFilledInput-root': {
        height: '56px',
        backgroundColor: 'unset',
        '&:not(:hover)': {
            backgroundColor: 'unset',
        },
        '&:hover': {
            backgroundColor: 'unset',
        },
        '& input': {
            caretColor: '#E25869',
            fontSize: '22px',
        },
        '.MuiInputBase-root-MuiInput-root::before': {
            borderBottom: 'unset'
        }
    },
    '& label.Mui-focused': {
        color: '#BBBBBB',
    },
});

const PasswordInput = props => {
    const { checkResult, currentValue, type, label } = props;
    const [inputValue, setInputValue] = useState(currentValue);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        setInputValue(currentValue)
    }, [currentValue])

    const changedInfo = (e) => {
        setInputValue(e.target.value)
        checkResult(e.target.value, type);
    }

    return (
        <div className={`${styles.mainContainer} flex-row`}>
            <CssFormControl sx={{ width: '100%' }} variant="filled">
                <InputLabel htmlFor="filled-adornment-password">{label}</InputLabel>
                <FilledInput
                    id="filled-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    value={inputValue}
                    label={label}
                    onChange={e => changedInfo(e)}
                />
            </CssFormControl>
        </div>
    )
}   

export default PasswordInput;