import React from "react";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import styles from './RegistrationStepper.module.scss';
import strings from "../../../assets/localization/strings.jsx";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    left: '-22px',
    right: '-43px',
    height: 4,
    width: '100%',
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      height: 4,
      zIndex: 1,
      background: "linear-gradient(0deg, rgba(221,55,135,1) 0%, rgba(253,108,89,1) 100%)",
    },
    [`&::after`]: {
        left: 0,
        position: 'absolute',
        top: '50%',
        height: '2px',
        backgroundColor: '#E8E8E8'
    }
  }));

function RegistrationStepper(props) {
  const { currentStep, steps } = props;
  
  function renderStep(step, key) {
    const { currentStep } = this;
    const done = key < currentStep;
    const current = key + 1 === currentStep;
    const stepIconClass = current ? styles.progressStep :
                           (done ? styles.completedStep : styles.inactiveStep)
    const titleClass = `${styles.title} ${current && styles.activeTitle} ${step.id === 0 && styles.smallTitle}`

    return (
      <li className={styles.registrationStepper__step} key={key}>
        <div className={styles.titleContainer}>
          <span
              className={titleClass}
              style={{"fontSize": strings.registration.steps.fontSize}}
          >
              {strings.registration.steps[step.id]}
          </span>
        </div>
        <div className={styles.labelContainer}>
            <span className={`${styles.defaultStep} ${stepIconClass}`}></span>
            {!!key && <BorderLinearProgress variant="determinate" value={done ? 100 : 0} className={styles.linearProgress} />}
        </div>
      </li>
    )
  }

  return (
    <ul className={styles.registrationStepper} style={{"fontSize": strings.registration.steps.fontSize}}>
      {steps.map(renderStep, { currentStep })}
    </ul>
  )
}

export default RegistrationStepper;