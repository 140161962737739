import { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import "./Picker.scss";

const Picker = props => {
    const { data, currentValue = 0, swipeValue, multiPicker, convertedValue, position } = props;
    const swiperRef = useRef();

    useEffect(() => {
        if(convertedValue) {
            if(data.length > convertedValue) {
                swiperRef.current.slideTo(convertedValue);
            }
        }
    }, [data, convertedValue]);

    return (
        <div className={`${multiPicker ? 'multipickerContainer' : 'pickerContainer'}`}>
            <Swiper
                className={position === 'left' ? 'left' : 'right' || ''}
                slidesPerView={'auto'}
                centeredSlides
                direction="vertical"
                grabCursor
                loop={!position}
                initialSlide={currentValue}
                onSlideChange={slide => swipeValue(slide.activeIndex)}
                onSwiper={(swiper) => swiperRef.current = swiper}
            >
                <div className={`${multiPicker ? 'disable' : 'activeLine'}`}></div>
                {data?.map((el, index) => (
                    <SwiperSlide key={index}>
                        <div className='slideContainer flex-row'>
                            <p>{ el }</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default Picker;