import {useState, useEffect, useRef} from "react";

import styles from "./MealPlanWithTimer.module.scss";

import strings from "../../../../assets/localization/strings.jsx";
import PaywallTimer from "../paywallTimer/PaywallTimer";
import LazyImage from "../../lazyImage/LazyImage";

const MealPlanWithTimer = (props) => {
    const { timerRef, stopTimer, setStopTimer, activeRate, themeData } = props;

    const containerRef = useRef(null);
    const [isSticky, setSticky] = useState(false);
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]}`

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            const elementPosition = containerRef.current.offsetTop;
            setSticky(scrollTop > elementPosition + 113);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const timerText = isSticky ? strings.paywall.greenTheme.mealPlan.stickyTimer : strings.paywall.greenTheme.mealPlan.timer
    return (
        <>
            <div ref={containerRef} className={mainContainerClass}>
                <div className={`${styles.boostBox} flex-row`}>
                    { themeData.paywallMealPlanTagImage ? (
                        <div className={styles.rocketImageBox} >
                            <LazyImage
                                className={styles.rocketImage}
                                srcWebp={themeData.paywallMealPlanTagImage.imageSrcWebp}
                                src={themeData.paywallMealPlanTagImage.imageSrc}
                                alt=""
                                style={themeData.paywallMealPlanTagImage.styles}
                            />
                        </div>
                    ) : (
                        <div className={styles.rocketImageBox} >
                            <LazyImage
                                className={styles.rocketImage}
                                srcWebp="/images/paywall/greenTheme/rocket.webp"
                                src="/images/paywall/greenTheme/rocket.png"
                                alt=""
                            />
                        </div>
                    )}
                    <div className={`${styles.infoBox} flex-column`} >
                        {strings.paywall.greenTheme.mealPlan.titleOne}
                        <span>{strings.paywall.greenTheme.mealPlan.titleTwo}</span>
                        {strings.paywall.greenTheme.mealPlan.titleThree}
                    </div>
                    <div className={`${styles.pricesBox} flex-column`} >
                        <div className={styles.newPrice} >$0</div>
                        <div className={styles.oldPrice} >$35<span></span></div>
                    </div>
                </div>
                <div className={`${isSticky ? styles.stickyBox : ""}`} >
                    <div className={`${styles.timerBox} flex-row`}>
                        {timerText}
                        <PaywallTimer
                            innerRef={timerRef}
                            activeRate={activeRate}
                            stopTimer={stopTimer}
                            setStopTimer={setStopTimer}
                            onlyTime={true}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MealPlanWithTimer;
