import {useEffect, useState} from "react";
import { useNavigate } from "react-router";

import ReactPixel from "react-facebook-pixel";
import TiktokPixel from 'tiktok-pixel';
import amplitude from 'amplitude-js';
import moment from "moment/moment";
import sha256 from "crypto-js/sha256";

import ButtonCard from "../../components/UI/buttonCard/ButtonCard";

import useJsonParse from "../../hooks/useJsonParse";
import styles from "./Intro.module.scss";

import strings from "../../assets/localization/strings.jsx";
import {Box, CircularProgress, Typography} from "@mui/material";
import {LinearProgressProps} from "@mui/material";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import LazyImage from "../../components/UI/lazyImage/LazyImage";
import BackgroundImageBox from "../../components/UI/backgroundImageBox/BackgroundImageBox";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';
const tiktokApiToken = 'e9cb49ddaa71504c0628507456187bbfb9368637';

function LinearProgressWithLabel(props: LinearProgressProps &
    { value: number, backgroundColor: string, textColor: string, shadow: string }) {
    return (
        <Box sx={{ width: '100%', position: 'relative' }}>
            <LinearProgress
                variant="determinate"
                thickness={24}
                value={props.value}
                sx={{
                    height: "20px",
                    borderRadius: '20px',
                    '--LinearProgress-radius': '20px',
                    '--LinearProgress-thickness': '24px',
                    border: `2px solid ${props.backgroundColor}`,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor: props.backgroundColor,
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: 5,
                        backgroundColor: props.color,
                    },
                }}
            >
            </LinearProgress>
            <Typography
                align={'center'}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    color: props.textColor,
                    fontSize: '13px',
                    fontWeight: '600',
                    lineHeight: '13px',
                    textShadow: props.shadow,
                    transitionX: '50%'
                }}
            >
                {`${Math.round(props.value)}%`}
            </Typography>

        </Box>
    );
}

const IntroPrefix = props => {
    const { pageInfo } = props;
    const prefixText = strings.introPrefixVariants[pageInfo.prefix]
    return (
        <div className={`${styles.prefixBox} flex-row`} style={pageInfo.prefixStyle}>
            { pageInfo.prefixWithImages && (
                <LazyImage
                    className={styles.prefixImage}
                    srcWebp={pageInfo.prefixImageWebp}
                    src={pageInfo.prefixImage}
                    alt=""
                />
            )}
            <span className={styles.prefix}>{prefixText}</span>
            { pageInfo.prefixWithImages && (
                <LazyImage
                    className={styles.prefixImage}
                    srcWebp={pageInfo.prefixImageWebp}
                    src={pageInfo.prefixImage}
                    alt=""
                />
            )}
        </div>
    )
}

const Intro = props => {
    const { data } = props;
    const pageInfo = data.settings.intro;
    const themeInfo = data.themeData;
    const cards = useJsonParse(pageInfo.mainGoals);
    const [tick, setTick] = useState(0)
    const [timer, setTimer] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        setTimer(setInterval(() => setTick(prev => prev + 1), 50));

        amplitude.getInstance().logEvent('screen_onboarding_intro_appeared');
        //localStorage.removeItem('discountStatus');
        const eventId = `viewcontent.${getCookie('id')}`;
        ReactPixel.track('ViewContent', {eventID: eventId});
        TiktokPixel.track('ViewContent', {event_id: eventId});
        fetch(`https://graph.facebook.com/v10.0/${(data.domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${data.domain.includes('net')? netToken: token}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: [
                    {
                        event_name: "ViewContent",
                        event_time: moment().unix(),
                        action_source: "website",
                        event_id: eventId,
                        user_data: {
                            client_user_agent: navigator.userAgent,
                            external_id: sha256(getCookie('id')).toString()
                        }
                    }
                ]
            })
        })
        const payload = {


                                                             "pixel_code": "CP2RST3C77UF83EV1CUG",
                                                             "event_id": eventId,
                                                             "event": "ViewContent",
                                                             "timestamp": new Date(Date.now()),
                                                             "context": {
                                                              user: {
                                                                    external_id: sha256(getCookie('id')).toString()
                                                                 },
                                                              user_agent: navigator.userAgent,
                                                             },
                                                           };

                                                           const ttc = document.cookie.split(';').filter(c => c.includes('tiktok_click_id=')).map(c => c.split('tiktok_click_id=')[1]).join();
                                                           const ttp = document.cookie.split(';').filter(c => c.includes('_ttp=')).map(c => c.split('_ttp=')[1]).join();

                                                           if(ttc) {
                                                             payload.context.user.ttclid = ttc;
                                                           }

                                                           if(ttp) {
                                                             payload.context.user.ttp = ttp;
                                                           }

                                                           try{

                                                           fetch('https://smvcqm8fu5.execute-api.us-east-1.amazonaws.com/dev/tiktok_pixel_event', {
                                                                   method: "POST",
                                                                   headers: {
                                                                        'Accept': 'application/json',
                                                                        'Content-Type': 'application/json'
                                                                   },
                                                                   body: JSON.stringify(
                                                                         payload
                                                                    ),
                                                                  });

                                                           } catch(e){
                                                           console.log("tiktok track event error" + e)
                                                           }

        return () => {
            setTick(0);
            clearInterval(timer);
        }
    }, [])

    useEffect(() => {
        if(tick === 100 && pageInfo.withLoading) {
            clearInterval(timer);
            setTimeout(async () => {
                navigate("steps");
                amplitude.getInstance().logEvent('button_onboarding_intro_tapped');
            }, 400)
        }
    }, [tick])

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const selectCard = card => {
        localStorage.setItem('goal', strings[card.section][card.page][card.localID].text);
        navigate('steps');
        amplitude.getInstance().logEvent('button_onboarding_intro_tapped', { answer: card.title});
    }

    const introHeader = strings.introHeaderVariants[pageInfo.header]

    return (
        <div
            className={`${styles.pageContainer} ${styles[`${themeInfo.pageContainer}PageContainer`]}`}
            style={pageInfo.pageBackgroundStyle}
        >
            <div className={`${styles.mainContainer} flex-column`}>
                <BackgroundImageBox
                    pageInfo={pageInfo}
                    src={strings.intro.backgroundImageWebp !== null ? strings.intro : pageInfo}
                />
                <div className={`${styles.absoluteContainer} flex-column`}>
                    <LazyImage
                        className={styles.whiteImage}
                        srcWebp={themeInfo.introLogo.srcWebp}
                        src={themeInfo.introLogo.src}
                        alt=""
                    />
                    <IntroPrefix pageInfo={pageInfo} campaign={data.campaign} />
                    <h1>{introHeader}</h1>
                    { cards && cards.length > 0 && (
                        <>
                            <span className={styles.subtitle}>{strings.introSubtitle}</span>
                            <LazyImage
                                className={styles.arrowDownImage}
                                srcWebp="/images/intro/arrow-down.webp"
                                src="/images/intro/arrow-down.png"
                                alt=""
                            />
                            <div className={`${styles.cardsContainer} flex-row`}>
                                { cards?.map(card => (
                                    <ButtonCard key={card.id} card={card} selectCard={selectCard} />
                                )) }
                            </div>
                        </>
                    )}
                    { pageInfo.withLoading && (
                        <>
                            {!pageInfo.withoutSubtitleLoading &&
                                (<span className={styles.subtitle}>{strings.introSubtitleLoadingVariants[data.settings.intro.introSubtitleLoadingVariant !== null && data.settings.intro.introSubtitleLoadingVariant !== undefined
                                                                                                                                                                                    ? data.settings.intro.introSubtitleLoadingVariant
                                                                                                                                                                                    : "common"]}</span>)
                            }
                            <div className={`${styles.loadingContainer} flex-column`}>
                                <span className={`${styles.subtitle} flex-row`}>
                                    {strings.introLoadingText}
                                    <CircularProgress color="inherit" size={11} thickness={5} />
                                </span>
                                <LinearProgressWithLabel
                                    value={tick}
                                    color={themeInfo.introLoading.color}
                                    textColor={themeInfo.introLoading.textColor}
                                    backgroundColor={themeInfo.introLoading.backgroundColor}
                                    shadow={themeInfo.introLoading.shadow}
                                />
                            </div>
                        </>
                    )}
                    <div className={`${styles.agreements} flex-column`}>
                        <span>{strings.introAgreements}</span>
                        <div className={`${styles.agreementLinks} flex-row`}>
                            <a href={'https://everdance.app/terms'}>Terms of Use</a>
                            <span className={styles.agreementInfo}>|</span>
                            <a href={'https://everdance.app/policy'}>Privacy Policy</a>
                        </div>
                        <span>2023 © {strings.introRights}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default Intro;