import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import StepSlider from '../stepSlider/StepSlider';

import styles from "./SwiperComponent.module.scss";
import 'swiper/css';
import LazyImage from "../lazyImage/LazyImage";

const SwiperComponent = props => {
    const { data, withoutBorder, defaultSlide, currentSlide, withTitle, sliderTitle, changeSlide } = props;
    const swiperRef = useRef();

    const swiperContainerClass = `${styles.swiperContainer} ${ withoutBorder ? styles.swiperContainerWithoutBorder : '' }`
    const swiperSlideClass = `${styles.swiperSlide} ${ withoutBorder ? styles.swiperWithoutBorder : styles.swiperSlideBorder}`

    return (
        <>
            <Swiper
                className={swiperContainerClass}
                spaceBetween={20}
                slidesPerView={"auto"}
                centeredSlides={true}
                lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
                initialSlide={currentSlide}
                onSlideChange={slide => changeSlide(slide.activeIndex)}
                onSwiper={(swiper) => swiperRef.current = swiper}
            >
                {data?.map(slide => (
                    <SwiperSlide className={swiperSlideClass} key={slide.id} >
                        <div className={styles.swiperImage} >
                            <LazyImage
                                notLazy={true}
                                srcWebp={slide.imageWebp}
                                src={slide.image}
                                alt=""
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            { withTitle && <span className={styles.sliderTitle}>{sliderTitle}</span> }
            <StepSlider swiperRef={swiperRef} defaultSlide={defaultSlide} currentSlide={currentSlide} sliderCount={data?.length - 1 || 0}/>
        </>
    )
}   

export default SwiperComponent;