import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import sessionData from "../../../core/static/plan/session.json";
import TextCard from "../../UI/textCard/TextCard";

import styles from "./Session.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const Session = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const [cards, setCards] = useState(data.settings.session.times ?? sessionData);
    const [activeCard, setActiveCard] = useState();
    const pageInfo = data.settings.session
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
       dataLayer.push({
                 "event": "ga4event",
                 "event_category": 'screen_onboarding_dance_session_appeared',
                 "funnel_type": data.campaign,
                 "step": pageInfo.order
                                     });
        amplitude.getInstance().logEvent('screen_onboarding_dance_session_appeared');
    }, [])

    const selectCard = (card) => {
        setActiveCard(card)
        amplitude.getInstance().logEvent('button_onboarding_dance_session_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "session");
        answer.set("value", card.title);
        nextStep(answer)
    }

    return (
        <div className={mainContainerClass}>
            <h1 className={styles.title}>{strings.plan.session.title[pageInfo.title]}</h1>
            { cards?.map(card => ( 
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard
                        card={card}
                        selectCard={selectCard}
                        activeCard={activeCard}
                        borderColor={themeData.checkboxCardColor}
                    />
                </div>)) 
            }
        </div>
    )
}   

export default Session;