import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';

import CheckboxCard from "../../UI/checkboxCard/CheckboxCard";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import styles from "./MainGoal.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const ChairMainGoal = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.mainGoal
    const [activeCards, setActiveCards] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const themeData = data.themeData
    const mainContainerClass = `${styles.mainContainer} ${styles.multiSelectContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
        dataLayer.push({
                                  "event": "ga4event",
                                  "event_category": "screen_onboarding_main_goal_appeared",
                                  "funnel_type": data.campaign,
                                  "step": pageInfo.order
        });
        amplitude.getInstance().logEvent('screen_onboarding_main_goal_appeared');
    }, [])


    const selectCard = card => {
        if(card.checked) {
            setActiveCards((prev => [...prev, card]))
        } else {
            const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
            setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
        }
    }

    useEffect(() => {
        if(activeCards.length !== 0) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [activeCards])

    const clickContinue = () => {
        const answerValue = activeCards.map(card => { return card.title})
        amplitude.getInstance().logEvent('button_onboarding_main_goal_tapped', {answer: answerValue});
        const answer = new Map();
        answer.set("key", "main_goal");
        answer.set("value", answerValue.join(', '));
        nextStep(answer);
    }

    return (
        <div className={mainContainerClass}>
            <h1>{strings.goal.mainGoal.title}</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>{strings.goal.mainGoal.subtitle}</span>
            { pageInfo.cards?.map(card => (
                <div key={card.id} className={styles.cardsWrapper}>
                     <CheckboxCard
                        key={card.id}
                        data={card}
                        smallCard={false}
                        selectCard={selectCard}
                        checkboxColor={themeData.checkboxCardColor}
                    />
                </div>
            )) }
            <ContinueButton
               nextStep={clickContinue}
               disableButton={disableButton}
               theme={themeData.continueButton}
            />
        </div>
    )
}   

export default ChairMainGoal;