import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';

import { styled } from "@mui/material/styles";
import styles from "./TextInput.module.scss";

const CssTextField = styled(TextField)({
    '&': {
        border: '1px solid #BBBBBB',
        borderRadius: '8px',
    },
    '& label': {
        fontSize: '1.1rem',
        color: '#BBBBBB',
    },
    '& .MuiFilledInput-underline:before': {
        borderBottom: 'unset !important'
    },
    '& .MuiFilledInput-underline:after': {
        borderBottom: 'unset !important'

    },
    '.MuiFilledInput-root': {
        height: '56px',
        backgroundColor: 'unset',
        '&:hover': {
            backgroundColor: 'unset',
        },
        '&:not(:hover)': {
            backgroundColor: 'unset',
        },
        '& input': {
            caretColor: '#E25869',
            fontSize: '22px',
        },
        '.MuiInputBase-root-MuiInput-root::before': {
            backgroundColor: 'unset',
            borderBottom: 'unset'
        }
    },
    '& label.Mui-focused': {
        backgroundColor: 'unset',
        color: '#BBBBBB',
    },
});

const TextInput = props => {
    const { checkResult, currentValue, onBlur, type, label } = props;
    const [inputValue, setInputValue] = useState(currentValue);

    useEffect(() => {
        setInputValue(currentValue)
    }, [currentValue])

    const changedInfo = (e) => {
        setInputValue(e.target.value)
        checkResult(e.target.value, type);
    }

    return (
        <div className={`${styles.mainContainer} flex-row`}>
            <CssTextField
                sx={{
                    '&:hover': { bgcolor: 'transparent' },
                }}
                fullWidth
                label={label}
                onBlur={onBlur}
                defaultValue={currentValue}
                variant="filled"
                color="warning"
                onChange={e => changedInfo(e)}
            />
        </div>
    )
}   

export default TextInput;