import styles from "./MealPlanBonus.module.scss";
import { useRef } from "react";

import GetButton from "../getProgramButton/GetButton";
import CustomCheckbox from "../../checkbox/Checkbox";
import MainGoal from "../../../goal/mainGoal/MainGoal";
import amplitude from 'amplitude-js';

import strings from "../../../../assets/localization/strings.jsx";
import LazyImage from "../../lazyImage/LazyImage";

const MealPlanAdvantages = () => {
    const checkboxData = {
        "checked": true,
        "disabled": true
    }
    return (
        <div className={styles.mealPlanBonusAdvantages}>
            <div className={styles.mealPlanBonusAdvantagesGroup}>
                <div className={styles.mealPlanBonusAdvantage}>
                    <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                    <div className={styles.advantagePoint}>
                    {strings.paywall.features[0].p1} <br/> {strings.paywall.features[0].p2 } <br/> {strings.paywall.features[0].p3 }
{/*                         Full access to <br/> 350+ dance <br/> classes */}
                    </div>
                </div>
                <div className={styles.mealPlanBonusAdvantage}>
                    <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                    <div className={styles.advantagePoint}>
                    {strings.paywall.features[5].p1} <br/> {strings.paywall.features[5].p2}
{/*                         Feedback from <br/> dance instructors */}
                    </div>
                </div>
            </div>
            <div className={styles.mealPlanBonusAdvantagesGroup}>
                <div className={styles.mealPlanBonusAdvantage}>
                    <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                    <div className={styles.advantagePoint}>
                     {strings.paywall.features[6].p1}  <br/> {strings.paywall.features[6].p2}  <br/> {strings.paywall.features[6].p3}
{/*                         Just 15 minutes per <br/> day to see <br/> noticeable results */}
                    </div>
                </div>
                <div className={styles.mealPlanBonusAdvantage}>
                    <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                    <div className={styles.advantagePoint}>
                     {strings.paywall.features[1].p1} <br/> {strings.paywall.features[1].p2} <br/>  {strings.paywall.features[1].p3}
{/*                         Personal <br/> dance plan */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const MealPlanBoxImage = ({ campaign, discount, style, img }) => {
    if(style === 'ny'){
     return (<></>)
    } else if(style === 'bf'){
     return (
         <LazyImage
             className={styles.bFGiftImage}
             srcWebp=""
             src={img}
             alt=""
         />
     )
    } else{
        return (
            <div className={styles.giftImage}>
                <LazyImage
                    srcWebp="/images/paywall/icons/surprise-icon.webp"
                    src="/images/paywall/icons/surprise-icon.png"
                    alt=""
                />
            </div>
        )
    }
}

const MealPlanBonus = (props) => {
    const { campaign, discount, style, img, withNutrition, nextText } = props;
    const ref = useRef(null);
    const scrollTo = () => {
        amplitude.getInstance().logEvent('paywall_scroll_tapped');

        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const blackFridayCase = style === 'bf'
    const newYearCase = style === 'ny'

    const containerStyle =  blackFridayCase ? styles.bFContainerBox :
                            newYearCase ? styles.nYContainerBox : ""

    return (
        <div className={styles.mealPlanBonus}>
            <MealPlanAdvantages />
            <div className={styles.mealPlanBonusBox}>
                <div className={`${styles.mealPlanBonusContainer} ${containerStyle}`}>
                    { newYearCase && (
                        <LazyImage
                            className={styles.boxBackgroundImage}
                            srcWebp="/images/paywall/paywall-free-bonus-ny.webp"
                            src="/images/paywall/paywall-free-bonus-ny.png"
                            alt=""
                        />
                    )}
                    <div className={styles.mealPlanBonusContent}>
                        <MealPlanBoxImage campaign={campaign} discount={discount} img={img} style={style} />
                        <div className={styles.mealPlanBonusTitle}>
                            <h2 className={ withNutrition ? styles.mealPlanBonusNutrition : null }>
                                <span className={styles.redText}>{strings.paywall.bonus.title}</span>
                                <br/>
                                { !withNutrition && ( <>
                                        {strings.paywall.bonus.mealPlan}
                                        <br/>
                                    </>
                                )}
                                {strings.paywall.bonus.hiit}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <GetButton text={nextText} nextPage={ scrollTo } />
            <div ref={ref} className={styles.mealPlanBonusRef} />
        </div>
      )
  }

export default MealPlanBonus;
