import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TryAgainButton from "../../components/UI/tryAgainButton/TryAgainButton";
import RegistrationStepper from "../../components/UI/registrationStepper/RegistrationStepper";

import amplitude from 'amplitude-js';
import styles from "./PaymentFailure.module.scss";
import stepsData from "../../core/static/createAccount/registrationSteps.json";
import LazyImage from "../../components/UI/lazyImage/LazyImage";

const Failure = () => {
    const navigate = useNavigate();
    const steps = stepsData;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_payment_failed_appeared');
    }, [])

    const clickTryAgain = () => {
        amplitude.getInstance().logEvent('button_onboarding_payment_failed_tryagain_tapped');
        navigate('/payment');
    }

    return (
        <div className={`${styles.mainContainer} flex-column container`}>
            <img loading="lazy" className={styles.logoImage} src="/images/logo-black.svg" alt="" />
            <RegistrationStepper steps={steps} currentStep={1} />

            <div className={`${styles.contentContainer} flex-column`}>
                <div className={styles.imageContainer}>
                    <LazyImage
                        src="/images/icons/failure-result.png"
                        srcWebp="/images/icons/failure-result.webp"
                        alt=""
                    />
                </div>
                <h1 className={styles.title}>Payment was not successful!</h1>
                <p className={styles.subtitle}>Please check your card data and try again.</p>
            </div>
            <TryAgainButton tryAgain={clickTryAgain}/>
        </div>
    )
}

export default Failure;