import styles from "./GuaranteeBox.module.scss";

import strings from "../../../../assets/localization/strings.jsx";
import LazyImage from "../../lazyImage/LazyImage";

const GuaranteeBox = (props) => {
    const { style } = props;
    const className = `${styles.mainContainer} ${styles[`${style}Campaign`]}`

    return (
        <div className={className}>
            <div className={styles.wrapper}>
                <h2>{strings.paywall.guarantee}</h2>

                { style === "lightGreen" ? (
                    <div className={styles.description} >
                        <p>{strings.paywall.results}<br/><br/>{strings.paywall.return}</p>
                        <div className={styles.guaranteeImage} >
                            <LazyImage
                                srcWebp="/images/paywall/greenTheme/guarantee.webp"
                                src="/images/paywall/greenTheme/guarantee.png"
                                alt=""
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <p>{strings.paywall.results}<br/><br/>{strings.paywall.return}</p>
                        <LazyImage
                            srcWebp="/images/paywall/guarantee-icon.webp"
                            src="/images/paywall/guarantee-icon.png"
                            alt=""
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default GuaranteeBox;
