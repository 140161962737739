import styles from "./LabelWithCheckbox.module.scss";
import CustomCheckbox from "../checkbox/Checkbox";

const LabelWithCheckbox = (props) => {
    const { labelText, theme } = props;
    const checkboxData = {
        "checked": true,
        "disabled": true
    }
    const mainContainerClass = `${styles.labelRow} ${styles[`${theme}Container`]}`

    return (
        <div className={mainContainerClass}>
            <CustomCheckbox data={checkboxData} color={theme} checkCard={()=>{}}/>
            <p className={styles.label}>{labelText}</p>
        </div>
    )
}

export default LabelWithCheckbox;