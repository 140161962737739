import { useEffect } from "react";
import amplitude from 'amplitude-js';

import styles from "./DanceVsExercises.module.scss";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";

const DanceVsExercises = props => {
    const { nextStep, data } = props;
    const danceExers = data.settings.danceExers;
    const themeData = data.themeData

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_your_plan_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_your_plan_continue_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader
                url={'/images/result/plan-result.jpg'}
                urlWebp={'/images/result/plan-result.webp'}
                theme={themeData?.menuProgressBackground}
            />
            <h1>{strings.plan.danceExercise.title}</h1>
            <span className={`${styles.marginTitle}`}>{strings.plan.danceExercise.subtitle}</span>
            <div className={`${styles.chartArea}`}>
                <LazyImage
                    srcWebp="/images/result/danceVsExercises.webp"
                    src="/images/result/danceVsExercises.png"
                    alt=""
                />
            </div>
            <div className={`${styles.infoBox} flex-row`}>
                <LazyImage
                    className={styles.backhandImage}
                    srcWebp="/images/icons/up_pointing_backhand.webp"
                    src="/images/icons/up_pointing_backhand.png"
                    alt=""
                />
                <p className={styles.subtitle}>
                    {strings.plan.danceExercise.info}
                </p>
            </div>
            <ContinueButton
                text={strings.plan.danceExercise.continue}
                nextStep={clickContinue}
                theme={themeData.continueButton}
            />
        </div>
    )
}

export default DanceVsExercises;
