import styles from "./Switcher.module.scss";

const SwitcherComponent = ({ options, value, onChange, theme }) => {
  const mainContainerClass = `${styles.switchContainer} ${styles[`${theme}Container`]} flex-between`

  return (
    <div className={mainContainerClass}>
      {/* <div className={styles.switchButton} ref={highlight}></div> */}
      {options.map(n => (
        <button
          key={n.value}
          className={`${n.value === value ? styles.isActive : ''}`}
          onClick={() => onChange(n.value)}
        >{n.text}</button>
      ))}
    </div>
  )
}

export default SwitcherComponent;