import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import TextCard from "../../UI/textCard/TextCard";

import styles from "./WorkoutTimes.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const WorkoutTimes = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.workoutTimes
    const themeData = data.themeData
    const [cards, setCards] = useState(pageInfo.cards);
    const [activeCard, setActiveCard] = useState();
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
       dataLayer.push({
                                 "event": "ga4event",
                                 "event_category": 'screen_onboarding_times_week_appeared',
                                 "funnel_type": data.campaign,
                                 "step": pageInfo.order
                      });
        amplitude.getInstance().logEvent('screen_onboarding_times_week_appeared');
    }, [])

    const selectCard = (card) => {
        setActiveCard(card)
        amplitude.getInstance().logEvent('button_onboarding_times_week_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "times");
        answer.set("value", card.title);
        nextStep()
    }

    return (
        <div className={mainContainerClass}>
            <h1 className={styles.title}>
                {strings.plan.times.title[pageInfo.title]}
            </h1>

            { cards?.map(card => (
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard
                        backgroundColor={themeData.cardBackgroundColor}
                        isBigImage={true}
                        card={card}
                        selectCard={selectCard}
                        borderColor={themeData.checkboxCardColor}
                    />
                </div>
            )) }
        </div>
    )
}   

export default WorkoutTimes;