import { useEffect, useState } from "react";

import WaitingRegistrationPopup from "../../UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import SuccessRegistrationPopup from "../../UI/successfulRegistrationPopup/SuccessRegistrationPopup";
import LiveClassCard from "../../UI/liveClassCard/LiveClassCard";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import amplitude from "amplitude-js";
import strings from "../../../assets/localization/strings.jsx";

import styles from "./LiveClasses.module.scss";

const LiveClasses = props => {
    const { nextStep, lessonCards } = props
    const textData = strings.registration.liveClasses
    const [activeCards, setActiveCards] = useState([]);
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)
    const [displaySuccessRegistrationPopup, setDisplaySuccessRegistrationPopup] = useState(false)
    const [disableButton, setDisableButton] = useState(true);

    const paypalId = 'Af7bctxkCUaREhFJ0BemXCIe_wjt63BQZ6lAPH58TSx-ew4SMvdCAXT5vKgf0iiAPGmGGo9TGSnsRddE'
    const testPaypalId = 'AVMWUzb4KSB_QhCJ6MAB72V7RgeM4KVLPSDcqhDDi6HPfXLB70erhueUmevRgsY74_kXlO4_ucmI75pP'
    const [paypalClientId, setPaypalClientId] = useState( localStorage.getItem('liveMode') === "true" ? paypalId : testPaypalId);

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_live_classes_appeared');
    }, [])

    const selectCard = (card) => {
        let newCards = []
        const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
        if(removeCardIndex === -1){
            newCards = [...activeCards, card]
        } else {
            newCards = [...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)]
        }
        setActiveCards(newCards)
        localStorage.setItem('activeCardsLive', newCards.map(card => {return card.id}))
    }

    useEffect(() => {
        if(activeCards.length !== 0) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [activeCards])

//     async function paypalSubscribe(data, actions) {
//         await clickContinueTest();
    const paypalSubscribe = (data, actions) => {
        setDisplayWaitingRegistrationPopup(true)

        let liveClassesIds = localStorage.getItem('activeCardsLive').split(",");

        const selectedCards = liveClassesIds.map(savedCard => { return lessonCards.find((element) => String(element.id) === savedCard)})

        const activeCardsTitle = selectedCards.map(card => { return card.title})
        amplitude.getInstance().logEvent('button_onboarding_add_to_my_plan_live_classes_tapped', {answer: activeCardsTitle});

        let boughtLessons = 0;

        let price;

        if( selectedCards.length === 5){
        price = 'P-1UV58924J8781561NM4SMWXA';
        }
        if( selectedCards.length === 4){
         price = 'P-61F03341KY5685409M4SM3CY';
        }
        if( selectedCards.length === 3){
         price = 'P-3YP30565UX1224056M4SM4WY';
        }
        if( selectedCards.length === 2){
         price = 'P-69996864P8318192WM4SM52I';
        }
        if( selectedCards.length === 1){
          price = selectedCards[0]["price_id"]["paypal"];
        }

        return actions.subscription.create({
               plan_id: localStorage.getItem('liveMode') === "true" ? price : "P-01W53846FU6608113M2QQS6A",
        });
    };

   // async function paypalOnApprove(data, details) {
   const paypalOnApprove = async (data, details) => {
         let boughtLessons = 0;
         let liveClassesIds = localStorage.getItem('activeCardsLive').split(",");
         const selectedCards = liveClassesIds.map(classId => { return lessonCards.find((element) => String(element.id) === classId)})

         for(let l = 0; l < selectedCards.length; l++){

         let price = selectedCards[l]["price_id"]["paypal"];
         let purchaseResult = await fetch("https://al9yh4rktb.execute-api.us-east-1.amazonaws.com/dev/buy_live_lesson_paypal", {
                                 method: "POST",
                                 headers: {
                                     'Accept': 'application/json',
                                     'Content-Type': 'application/json'
                                 },
                                 body: JSON.stringify({
                                     priceId: price,
                                     customerId: localStorage.getItem('customer_id'),
                                     live_lesson: String(selectedCards[l].id),
                                     profileId: localStorage.getItem('profile_id'),
                                     email: localStorage.getItem('emailInfo'),
                                     amplitude_user_id: amplitude.getInstance().options.userId,
                                     location: localStorage.getItem('search'),
                                     onboarding_id: localStorage.getItem('onboarding_id'),
                                     livemode: localStorage.getItem('liveMode'),
                                     subscr_id: data.subscriptionID,
                                 }),
                      }).then(async (response) => {

                                 let resultBody = response.json();
                                 await resultBody.then( async (json) => {

                                     return json
                                 });
                              });

                       boughtLessons++;
        }

         if(boughtLessons === selectedCards.length)  {
                 setDisplayWaitingRegistrationPopup(false)
                        setDisplaySuccessRegistrationPopup(true)
                        setTimeout(() => {
                            setDisplaySuccessRegistrationPopup(false)
                            nextStep();
                        }, 3000);
                }
    }

    const clickContinue = async () => {
        setDisplayWaitingRegistrationPopup(true)
        const activeCardsTitle = activeCards.map(card => { return card.title})
        amplitude.getInstance().logEvent('button_onboarding_add_to_my_plan_live_classes_tapped', {answer: activeCardsTitle});

        let boughtLessons = 0;

          if(localStorage.getItem('paymentMethodType') === "stripe"){

             for(let l = 0; l < activeCards.length; l++){

             let price = activeCards[l]["price_id"]["stripe"];
             let purchaseResult = await fetch("https://al9yh4rktb.execute-api.us-east-1.amazonaws.com/dev/buy_live_lesson", {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            priceId: price,
                            customerId: localStorage.getItem('customer_id'),
                            live_lesson: String(activeCards[l].id),
                            profileId: localStorage.getItem('profile_id'),
                            paymentMethodId: localStorage.getItem('paymentMethodId'),
                            email: localStorage.getItem('emailInfo'),
                            amplitude_user_id: amplitude.getInstance().options.userId,
                            location: localStorage.getItem('search'),
                            onboarding_id: localStorage.getItem('onboarding_id'),
                            livemode: localStorage.getItem('liveMode'),
                        }),
             }).then(async (response) => {

                        let resultBody = response.json();
                        await resultBody.then( async (json) => {

                            return json
                        });
                     });

              boughtLessons++;
             }
          }

        if(boughtLessons === activeCards.length)  {
         setDisplayWaitingRegistrationPopup(false)
                setDisplaySuccessRegistrationPopup(true)
                setTimeout(() => {
                    setDisplaySuccessRegistrationPopup(false)
                    nextStep();
                }, 3000);
        }


    }

    const confirmAction = async () => {
        setDisplayWaitingRegistrationPopup(true)
        amplitude.getInstance().logEvent('button_onboarding_add_to_my_plan_tapped');
    }

    return (
        <>
            { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
            { displaySuccessRegistrationPopup ? (<SuccessRegistrationPopup />) : null }

            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.title}>{textData.title}</h1>
                <div className={styles.subtitleBox}>
                    <span className={styles.subtitle}>{textData.subtitle}</span>
                    <span className={styles.subtitleBold}>{textData.subtitleBold}</span>
                </div>
                <div className={styles.formContainer}>
                    { lessonCards.length === 1 ? (
                        <LiveClassCard
                            small={false}
                            cardData={lessonCards[0]}
                            selectCard={selectCard}
                            activeCard={activeCards.indexOf(lessonCards[0])!==-1}
                        />
                    ) : (
                        <div className={styles.cardsContainer}>
                            { lessonCards?.map((card, idx) => (
                                <LiveClassCard
                                    small={true}
                                    key={idx}
                                    cardData={card}
                                    selectCard={selectCard}
                                    activeCard={activeCards.indexOf(card)!==-1}
                                />
                            ))}
                        </div>
                        )
                    }
                </div>
               {
                    localStorage.getItem('paymentMethodType') === "stripe"
                    ?  <button
                    className={`${styles.buttonContainer} ${disableButton ? styles.disabledButton : ''}`}
                    onClick={clickContinue}
                    disabled={!!disableButton} >
                    {textData.continueButton}
                      </button>
                  :   ( disableButton === false && <PayPalScriptProvider options={{ "client-id": paypalClientId, vault:true }}>
                                                               <PayPalButtons
                                                                   style={{ layout: "horizontal", width: 185, height: 48, borderRadius: 8 }}
                                                                   createSubscription={(data, details) => paypalSubscribe(data, details)}
                                                                   onApprove={(data, details) => paypalOnApprove(data, details)}
                                                                   onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_paypal_tapped')}
                                                               />
                                                   </PayPalScriptProvider>)
               }
            </div>
        </>
    )
}

export default LiveClasses;