import styles from "./WaitingRegistrationPopup.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const WaitingRegistrationPopup = () => {
    return (
        <div className={styles.popupWindow}>
            <div className={styles.popupContainer}>
                <div className={styles.loadingRing}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <p>{strings.wait}</p>
            </div>
        </div>
    )
}   

export default WaitingRegistrationPopup;