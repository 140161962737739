import styles from "./TryAgainButton.module.scss";

const TryAgainButton = props => {
    const { text = 'Try again', tryAgain, disableButton } = props;

    return (
        <button className={`${styles.buttonContainer} ${disableButton ? styles.disabledButton : ''}`} onClick={tryAgain} disabled={disableButton ? true : false}>
            {text}
        </button>
    )
}   

export default TryAgainButton;