import LazyImage from "../../lazyImage/LazyImage";
import {Autoplay, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import styles from "./HeaderSwiper.module.scss";
import strings from "../../../../assets/localization/strings.jsx";
import VideoFeedbacks from "../../videoFeedbacks/VideoFeedbacks";


const ImageSlide = (props) => {
    const { settings } = props

    return (
        <div className={styles.swiperCard}>
            <LazyImage
                className={styles.logoImage}
                src={settings.src}
                srcWebp={settings.srcWebp}
                alt=""
            />
        </div>
    )
}

const VideoSlide = (props) => {
    const { settings, themeData } = props

    return (
        <div className={styles.swiperCard}>
            <VideoFeedbacks
                feedbacks={[settings]}
                title={false}
                name={false}
                view="square"
                themeData={themeData}
                isFull={true}
            />
        </div>
    )
}

const ChartSlide = (props) => {
    const { settings, currentWeight, targetWeight } = props

    return (
        <div className={`${styles.swiperCard} flex-column`}>
            <div className={styles.swiperChart}>
                <div className={`${styles.chartText} ${styles.chartWeightCurrent}`} >
                    {currentWeight}
                </div>
                <div className={styles.chartImage}>
                    <LazyImage
                        className={styles.image}
                        src={settings.src}
                        srcWebp={settings.srcWebp}
                        alt=""
                    />
                </div>
                <div className={`${styles.chartText} ${styles.chartWeightTarget}`} >
                    {targetWeight}
                </div>
            </div>
            <div className={styles.chartNotice}>
                {strings.paywall.greenTheme.chartNotice}
            </div>
        </div>
    )
}

const HeaderSwiper = (props) => {
    const { data, theme, currentWeight, targetWeight } = props
    const mainContainerClass = `${styles.mainContainer} ${styles[`${theme.pageContainer}Container`]} `

    return (
        <Swiper
            className={mainContainerClass}
            spaceBetween={30}
            slidesPerView={"auto"}
            centeredSlides={true}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
        >
            { data.map((sliderData, idx) => (
                    <SwiperSlide key={idx} className={sliderData.type === 'chart' && styles.swiperSlide}>
                        {sliderData.type === 'image' ?
                            (<ImageSlide settings={sliderData} />) :
                            sliderData.type === 'video' ?
                            (<VideoSlide settings={sliderData} themeData={theme} />) :
                            (<ChartSlide
                                settings={sliderData}
                                currentWeight={currentWeight}
                                targetWeight={targetWeight}
                            />)
                        }
                    </SwiperSlide>
                )
            )}
        </Swiper>
    )
}

export default HeaderSwiper;