import { useState, useEffect } from 'react';

const useJsonParse = (data, testElements = null) => {
  const [parsedData, setParsedData] = useState(null);

  useEffect(() => {
    setParsedData(testElements ? data[testElements] : data);
  }, [data, testElements]);

  return parsedData;
}

export default useJsonParse

