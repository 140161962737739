import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service like AppSignal
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
     console.log(error)
     let errorBody = {
          "body": {
                "user": localStorage.getItem('onboarding_id'),
                "error": error.toString,
                "trace": error.stack,
                "platform": "web"
                 }
            }
            fetch("https://smvcqm8fu5.execute-api.us-east-1.amazonaws.com/dev/log_error_web", {
                method: "POST",
                body: JSON.stringify(errorBody)
            }).then(async (result) => {
                console.log(result)
                if(result.status === 200){

                } else {

                }}).catch(async (e) => {
                             setError()
                         })
      // You can render any custom fallback UI
      return (
        <div>
          <p>Something went wrong 😭</p>

          {error.message && <span>Here's the error: {error.message}</span>}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
