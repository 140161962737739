import {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./BadHabits.module.scss";
import useJsonParse from "../../../hooks/useJsonParse";
import badHabitsData from "../../../core/static/nutrition/badHabits.json";
import CheckboxCard from "../../UI/checkboxCard/CheckboxCard";

const cardStyles = {
    width: '100%'
}

const buttonStyles = {
    position: 'relative',
    marginTop: '45px',
    left: 'unset'
}

const BadHabits = props => {
    const { nextStep } = props;
    const [cards, setCards] = useState([]);
    const [activeCards, setActiveCards] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const parsedData = useJsonParse(badHabitsData);

    const selectCard = card => {
        if(card.checked) {
            if(card.id === 4) {
                setActiveCards([card])
            } else {
                if(activeCards.length === 1 && activeCards[0].id === 4) {
                    const removeCardIndex = activeCards.findIndex(el => el.id === 4)
                    let tempCards = [...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)]
                    setActiveCards([...tempCards, card])
                } else {
                    setActiveCards((prev => [...prev, card]))
                }
            }
        } else {
            const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
            setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
        }
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_bad_habits_appeared');
    }, [])

    useEffect(() => {
        setDisableButton(activeCards.length === 0);
    }, [activeCards])

    useEffect(() => {
        setCards(parsedData);
    }, [parsedData]);

    const clickContinue = () => {
        let answers = activeCards.map(card => { return card.title})
        amplitude.getInstance().logEvent('button_onboarding_bad_habits_continue_tapped', {answer: answers});
        const answer = new Map();
        answer.set("key", "motivation");
        answer.set("value", answers);
        nextStep(answer);
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>{strings.nutrition.badHabits.title}</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>{strings.nutrition.badHabits.subtitle}</span>
            <div className={`${styles.cardsContainer} flex-column`}>
                { cards?.map(card => (
                    <CheckboxCard
                        key={card.id}
                        data={card}
                        cardStyles={cardStyles}
                        selectCard={selectCard}
                        isActive={activeCards.findIndex(el => el.id === card.id)}
                    />
                )) }
            </div>
            <ContinueButton
                customStyles={buttonStyles}
                text={strings.continue}
                nextStep={clickContinue}
                disableButton={disableButton}
            />
        </div>
    )
}

export default BadHabits;