import { useState, useEffect } from "react";
import Slider from '@mui/material/Slider';

import styles from "./StepSlider.module.scss";
import "./StepSlider.scss";

const StepSlider = props => {
    const { swiperRef, defaultSlide, currentSlide, sliderCount } = props;
    const [step, setStep] = useState(defaultSlide);
    const [colorThumb, setColorThumb] = useState("red");
    
    const changeStep = value => {
        swiperRef.current.slideTo(value);
        setStep(value);
    }

    function findColour() {
        switch(sliderCount){
            case 2:
                switch(step){
                    case 1:
                        return 'yellow'
                    case 2:
                        return 'blue'
                    case 0:
                    default:
                        return 'orange'
                }
            case 5:
            default:
                switch(step){
                    case 2:
                        return 'orange'
                    case 3:
                        return 'yellow'
                    case 4:
                        return 'green'
                    case 5:
                        return 'blue'
                    case 0:
                    case 1:
                    default:
                        return 'red'
                }
        }
    }

    useEffect(() => {
        setColorThumb(findColour())
    }, [step, sliderCount])

    useEffect(() => {
        setStep(currentSlide)
    }, [currentSlide])

    return (
        <div className={sliderCount === 5 ? 'stepSliderContainer' : 'stepSliderContainerMini'}>
            <Slider
                className={colorThumb}
                defaultValue={defaultSlide}
                value={currentSlide}
                step={1}
                marks
                min={0}
                max={sliderCount}
                onChange={e => changeStep(e.target.value)}
            />
        </div>
    )
}   

export default StepSlider;