import {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./TypeOfDiet.module.scss";
import useJsonParse from "../../../hooks/useJsonParse";
import dietData from "../../../core/static/nutrition/typeOfDiet.json";
import CheckboxCard from "../../UI/checkboxCard/CheckboxCard";

const cardStyles = {
    width: '100%'
}

const buttonStyles = {
    position: 'relative',
    marginTop: '45px',
    left: 'unset',
    width: '100%'
}

const TypeOfDiet = props => {
    const { nextStep } = props;

    const [cards, setCards] = useState();
    const [activeCard, setActiveCard] = useState();
    const [disableButton, setDisableButton] = useState(true);
    const parsedData = useJsonParse(dietData);

    const selectCard = card => {
        setActiveCard(card.checked ? card : null);
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_type_of_diet_appeared');
    }, [])

    useEffect(() => {
        setDisableButton(!activeCard?.title);
    }, [activeCard])

    useEffect(() => {
        setCards(parsedData);
    }, [parsedData]);

    const clickContinue = () => {
        let answers = activeCard.title
        amplitude.getInstance().logEvent('button_onboarding_type_of_diet_continue_tapped', {answer: answers});
        const answer = new Map();
        answer.set("key", "type_of_diet");
        answer.set("value", answers);
        localStorage.setItem('type_of_diet', answers);
        nextStep(answer);
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>{strings.nutrition.typeOfDiet.title}</h1>

            <div className={`${styles.cardsContainer} flex-column`}>
                { cards?.map(cardsBlock => (
                    <div className={styles.cardsBlock} key={cardsBlock.id}>
                        <span className={`${styles.subtitle}`}>
                            {strings.nutrition.typeOfDiet[cardsBlock.type]}
                        </span>
                        { cardsBlock.cards?.map(card => (
                            <CheckboxCard
                                key={card?.id}
                                data={card}
                                cardStyles={cardStyles}
                                withSubtitle={true}
                                selectCard={selectCard}
                                isActive={activeCard?.id === card?.id ? 1 : -1}
                            />
                        )) }
                    </div>
                    ))
                }
                <ContinueButton customStyles={buttonStyles}
                                text={strings.continue}
                                nextStep={clickContinue}
                                disableButton={disableButton}
                />
            </div>
        </div>
    )
}   

export default TypeOfDiet;