import { useEffect, useState } from "react";
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { jwtDecode } from "jwt-decode";
import TextInput from "../../UI/textInput/TextInput";
import PasswordInput from "../../UI/passwordInput/PasswordInput";
import UserPool from "./UserPool";
import ASIUserPool from "./ASIUserPool";
import amplitude from 'amplitude-js';
import styles from "./RegistrationForm.module.scss";
import AppleLogin from "react-apple-login";
import { FacebookProvider, LoginButton, useLogin, useFacebook } from 'react-facebook';
import { GoogleOAuthProvider } from "@react-oauth/google"
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import React from 'react';
import axios from "axios";
import updatePaymentEvent from "../../../hooks/facebookCAPI";
import strings from "../../../assets/localization/strings.jsx";

const RegistrationFormSelect = props => {
    const { nextStep, switchOption, setClosedPopup, setWaitingPopup, domain } = props
    const emailInfo = localStorage.getItem('emailInfo')
    const subscriptionId = localStorage.getItem('subscriptionId')
    const [disableButton, setDisableButton] = useState(true)
    const [nickname, setNickname] = useState('')
    const [fullName, setFullName] = useState('')
    const [fbID, setFbID] = useState(null)
    const [email, setEmail] = useState(emailInfo)
    const [errorMessage, setErrorMessage] = useState('')
    const [infoMessage, setInfoMessage] = useState('')
    const [ googleUser, setGoogleUser ] = useState(null);
    const { login, status, isLoading, error} = useLogin();
    const { init } = useFacebook();

    function getCookie(name) {
           const matches = document.cookie.match(new RegExp(
             "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
           ));
           return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    useEffect(() => {

        setDisableButton(true)
        amplitude.getInstance().logEvent('screen_onboarding_create_account_appeared');
        return () => {
            setDisableButton(true)
        }
    }, [])



    useEffect(
            () => {
                if (googleUser) {
                   console.log(googleUser);
                    axios
                        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
                            headers: {
                                Authorization: `Bearer ${googleUser.access_token}`,
                                Accept: 'application/json'
                            }
                        })
                        .then((res) => {
                        console.log(res.data)
                        const email = res.data.email;
                        console.log(googleUser.access_token);
                        getIdentityId('google', googleUser.access_token);
                        })
                        .catch((err) => console.log(err));
                }
            },
            [ googleUser ]
        );

    const getGoogleUser = async (access_token) => {
      axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
                                headers: {
                                    Authorization: `Bearer ${access_token}`,
                                    Accept: 'application/json'
                                }
                            })
                            .then((res) => {
                            console.log(res.data);

                            return res.data;
                            })
                            .catch((err) => console.log(err));
    }

    const getCognitoId = async (IdentityId, token) => {

         fetch('https://cognito-identity.us-east-2.amazonaws.com/', {
                           method: "POST",
                           //com.amazonaws.cognito.identity.model.
                           headers: {
                               'Accept': 'application/json',
                               'Content-Type': 'application/x-amz-json-1.1',
                               'X-Amz-Target': 'AWSCognitoIdentityService.GetCredentialsForIdentity',
                               'X-Amz-User-Agent': 'aws-amplify/0.0.x dart',
                           },
                           body: JSON.stringify({
//                             "AccountId": "858694396528",
                            'Logins': {
                            'accounts.google.com': token
                            },
                           "IdentityId": IdentityId
                           })
                     }).then(async (result) => {
                       console.log(result)
                       let resultBody = result.json();
                        await resultBody.then( async (json) => {
                        console.log(json)

                       });
                       if(result.status === 200){

                       } else {

                           setError()
                       }
                   }).catch(async (e) => {
                       console.log(e)
                       setError()
                   })

   //     X-AMZ-DATE: 20140730T232759Z

       }

    const checkEmail = async (email) => {
      let checkEmailResponse;
      await fetch("https://k1ltj4j168.execute-api.us-east-1.amazonaws.com/dev/check_email_exists", {
                 method: "POST",
                 body: JSON.stringify({ "email": email}),
             }).then(async (response) => {
                 let resultBody = response.json();
                 await resultBody.then( async (json) => {
                 checkEmailResponse = json;
                 });
             });
      return checkEmailResponse;
    }

    const checkIdentityExist = async (identityId) => {
    const identityIdValue = identityId.split(":")[1];
    let identityExists = false;
    await fetch('https://wg39cf30li.execute-api.us-east-1.amazonaws.com/dev/check_identity_exists', {
                            method: "POST",
                            body: JSON.stringify({
                            "body": {
                             "identity_id": identityIdValue
                              }
                             })
                           }
                 ).then(async (result) => {
                        console.log(result)

                        if(result.status === 200){
                         let resultBody = result.json();
                         await resultBody.then( async (json) => {
                               console.log(json)
                               identityExists = json.user_exist;
                               localStorage.setItem('profile_id', json.id);
                        });
                        } else {

                            setError()
                        }
                    }).catch(async (e) => {
                        console.log(e)
                        setError()
                    })
         return identityExists;
    }

    const getIdentityId = async (authenticator, token) => {
      let identityId = null;
      await fetch('https://cognito-identity.us-east-2.amazonaws.com/', {
                        method: "POST",
                        //com.amazonaws.cognito.identity.model.
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-amz-json-1.1',
                            'X-Amz-Target': 'AWSCognitoIdentityService.GetId',
//                             'X-Amz-User-Agent': 'aws-amplify/0.0.x dart',
                        },
                        body: JSON.stringify({
//                          "AccountId": "858694396528",
                         'Logins': authenticator === 'facebook'
                         ? {
                         'graph.facebook.com': token
                         }
                         : authenticator === 'google'
                         ? {
                           'accounts.google.com': token
                          }
                          : authenticator === 'apple'
                        ? {
                           'appleid.apple.com': token
                          }
                          : {},
                        "IdentityPoolId": "us-east-2:650ccafc-c8af-472c-8217-e27419730e82"})
                       }
             ).then(async (result) => {
                    console.log(result)

                    if(result.status === 200){
                     let resultBody = result.json();
                     await resultBody.then( async (json) => {
                           console.log(json)
                            identityId = json.IdentityId;

                    });
                    } else {

                    setError()
                    }
                }).catch(async (e) => {
                    console.log(e)
                    setError()
                })
       return identityId;
    }

    const clickContinueApple = () => {
        setDisableButton(true)
        setWaitingPopup(true)
        amplitude.getInstance().logEvent('button_onboarding_create_account_create_account_tapped');
        const attributeList = [];
        attributeList.push(new CognitoUserAttribute({ Name: 'email', Value: inputEmail.toLowerCase() }));
        attributeList.push(new CognitoUserAttribute({ Name: 'nickname', Value: inputEmail.toLowerCase() }));
        ASIUserPool.signUp(inputEmail.toLowerCase(), inputPassword, attributeList, null, (err, data) => {
            if (err) {
                setError()
            } else {
                signUp(data.userSub)
            }
        });
    }

   const appleResponse = async (appleResponse) => {
   console.log("appleResponse");
   console.log(appleResponse.authorization);
   const decoded = jwtDecode(appleResponse.authorization.id_token);

   console.log(decoded);

   await fetch(`https://wg39cf30li.execute-api.us-east-1.amazonaws.com/dev/create_asi_cognito_user`, {
                method: "POST",
                body: JSON.stringify({"body": {"email": decoded.email, "nickname": decoded.email, "password": decoded.sub}})
                })
                .then(async (response) => {
                                 let resultBody = response.json();
                                 await resultBody.then( async (json) => {

                                 if(json.created){

                                 } else{

                                 }
                                   await fetch(`https://y5roy4hzo7.execute-api.us-east-1.amazonaws.com/dev/jwt_encode`, {
                                                  method: "POST",
                                                  body: JSON.stringify({"body": {"token": appleResponse.authorization.id_token}})
                                                  })
                                                  .then(async (response) => {
                                                   let resultBody = response.json();
                                                      await resultBody.then( async (json) => {
                                                         if(json.token) {
                                                            let identityId = await getIdentityId('apple', json.token);
                                                            let exists = await checkIdentityExist(identityId);
                                                            console.log("identity found: " + exists);
                                                          }
                                                      });
                                                  });
                                   });
                 });

   };

    async function handleFacebookLogin() {
        const fbApi = await init();
            try {
            const response = await fbApi.login({  scope: 'public_profile,email' });
            const FB = await fbApi.getFB(); // Get original FB object
            console.log(response.status);
            setFbID(response.authResponse.userID);
            localStorage.setItem('fb_id', response.authResponse.userID);

            let identityId = await getIdentityId('facebook', response.authResponse.accessToken);
            let exists = await checkIdentityExist(identityId);

            console.log("identity found: " + exists);
                     if(exists){
                         const identityIdValue = identityId.split(":")[1];
                         await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_email", {
                                                     method: "POST",
                                                     headers: {
                                                         'Accept': 'application/json',
                                                         'Content-Type': 'application/json'
                                                     },
                                                     body: JSON.stringify({
                                                         body: {
                                                             cognito_id: identityIdValue,
                                                         }
                                                     }),
                                                 }).then(async (response) => {
                                                     let resultBody = response.json();
                                                     await resultBody.then(async (json) => {

                                                          console.log(json.result.users[0]);
                                                          setEmail(json.result.users[0].email);
                                                          setFullName(json.result.users[0].name);
                                                          localStorage.setItem('fullName', json.result.users[0].name);
                                                          signUpPaymentEvent();
                                                         localStorage.setItem('profile_id', json.result.users[0].id);
                                                     });
                                                 });


                         amplitude.getInstance().logEvent('button_onboarding_create_account_account_created_fb')
                         setWaitingPopup(false)
                         setDisableButton(false)

                         nextStep();
                     } else{
                     const identityIdValue = identityId.split(":")[1];
                        await axios.get(`https://graph.facebook.com/v2.12/me?fields=name,first_name,last_name,email,picture.height(500).width(500),birthday&access_token=${response.authResponse.accessToken}`, {
                                                                            headers: {
                                                                                Authorization: `Bearer ${response.authResponse.accessToken}`,
                                                                                Accept: 'application/json'
                                                                            }
                                                                        })
                                                                        .then(async (res) => {
                                                                        console.log(res.data);
                                                                        let checkEmailMap = await checkEmail(res.data.email);
                                                                        if(checkEmailMap.email_exist){
                                                                           setErrorMessage(strings.registration.emailExists)
                                                                        } else{
                                                                           setNickname(checkEmailMap.preferred_nickname)
                                                                           setInfoMessage(strings.registration.emailOk)
                                                                           setEmail(res.data.email);
                                                                           setFullName(res.data.first_name + " " + res.data.last_name);
                                                                           let body = {
                                                                                 "name": res.data.first_name + " " + res.data.last_name,
                                                                                 "email": res.data.email,
                                                                                 "cognitoUserId": identityIdValue,
                                                                                 "authenticator": "graph.facebook.com",
                                                                                 "nickname": nickname,
                                                                                 "platform": "web",
                                                                                 "amplitude_user_id": amplitude.getInstance().options.userId,
                                                                                 "subscription_id": subscriptionId,
                                                                                 "onboarding_id": localStorage.getItem('onboarding_id')
                                                                           };
                                                                           fetch("https://k1ltj4j168.execute-api.us-east-1.amazonaws.com/dev/create_user_profile", {
                                                                                                                                   method: "POST",
                                                                                                                                   body: JSON.stringify(body)
                                                                                }).then(async (result) => {
                                                                                                            console.log(result)
                                                                                                            let resultBody = result.json();
                                                                                                            await resultBody.then( async (json) => {
                                                                                                              console.log(json)
                                                                                                              localStorage.setItem('profile_id', json.id);
                                                                                                            });
                                                                                                            if(result.status === 200){
                                                                                                              amplitude.getInstance().logEvent('button_onboarding_create_account_account_created')
                                                                                                              signUpPaymentEvent();
                                                                                                              setWaitingPopup(false)
                                                                                                              setDisableButton(false)
                                                                                                              nextStep();
                                                                                                            } else {
                                                                                                              setError()
                                                                                                            }
                                                                                }).catch(async (e) => {
                                                                                    setError()
                                                                                })
                                                                        }

                                                                        return res.data;
                                                                        })
                        .catch((err) => console.log(err));

                     }

               } catch (error) {
                 console.log(error.message);
               }
    }

    const googleLogin = useGoogleLogin({
           onSuccess: async ({ code }) => {
                        let body = {
                                    "code": code
                                }
                        fetch("https://y5roy4hzo7.execute-api.us-east-1.amazonaws.com/dev/google_auth", {
                                    method: "POST",
                                    body: JSON.stringify(body)
                                }).then(async (result) => {
                                    console.log(result)
                                    let resultBody = result.json();
                                     await resultBody.then( async (json) => {
                                     console.log(json)
                                     let tokensBody = json.tokens;
                                     let tokens = tokensBody.tokens;
                                     let identityId = await getIdentityId('google', tokens.id_token);
                                     let exists = await checkIdentityExist(identityId);
                                     console.log("identity found: " + exists);

                                     if(exists){
                                      const identityIdValue = identityId.split(":")[1];
                                      await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_email", {
                                                                                          method: "POST",
                                                                                          headers: {
                                                                                              'Accept': 'application/json',
                                                                                              'Content-Type': 'application/json'
                                                                                          },
                                                                                          body: JSON.stringify({
                                                                                              body: {
                                                                                                  cognito_id: identityIdValue,
                                                                                              }
                                                                                          }),
                                                                                      }).then(async (response) => {
                                                                                          let resultBody = response.json();
                                                                                          await resultBody.then(async (json) => {
                                                                                              console.log(json.result.users[0])
                                                                                               setEmail(json.result.users[0].email);
                                                                                               setFullName(json.result.users[0].name);
                                                                                               localStorage.setItem('fullName', json.result.users[0].name);
                                                                                               signUpPaymentEvent();
                                                                                              localStorage.setItem('profile_id', json.result.users[0].id);
                                                                                          });
                                                                                      });

                                     amplitude.getInstance().logEvent('button_onboarding_create_account_account_created_gmail')
                                      setWaitingPopup(false)
                                      setDisableButton(false)
                                      nextStep();

                                     } else{
                                     const identityIdValue = identityId.split(":")[1];
                                     let googleUserMap = await getGoogleUser(tokens.access_token);
                                     console.log(googleUserMap);
                                     let userEmail = googleUserMap.email;
                                     setEmail(userEmail);
                                     setFullName(googleUserMap.name);
                                     let checkEmailMap = await checkEmail(userEmail);
                                     if(checkEmailMap.email_exist){
                                        setErrorMessage(strings.registration.emailExists)
                                     }
                                     else{
                                         setNickname(checkEmailMap.preferred_nickname)
                                         setInfoMessage(strings.registration.emailOk)
                                            let body = {
                                                 "name": googleUserMap.name,
                                                 "email": userEmail,
                                                 "cognitoUserId": identityIdValue,
                                                 "authenticator": "accounts.google.com",
                                                 "nickname": nickname,
                                                 "platform": "web",
                                                 "amplitude_user_id": amplitude.getInstance().options.userId,
                                                 "subscription_id": subscriptionId,
                                                 "onboarding_id": localStorage.getItem('onboarding_id')
                                            }
                                            fetch("https://k1ltj4j168.execute-api.us-east-1.amazonaws.com/dev/create_user_profile", {
                                                        method: "POST",
                                                        body: JSON.stringify(body)
                                                    }).then(async (result) => {
                                                        console.log(result)
                                                        let resultBody = result.json();
                                                         await resultBody.then( async (json) => {
                                                               console.log(json)
                                                               localStorage.setItem('profile_id', json.id);
                                                        });
                                                        if(result.status === 200){
                                                            signUpPaymentEvent();
                                                            amplitude.getInstance().logEvent('button_onboarding_create_account_account_created')
                                                            setWaitingPopup(false)
                                                            setDisableButton(false)
                                                            nextStep();
                                                        } else {
                                                            setError()
                                                        }
                                                    }).catch(async (e) => {
                                                        setError()
                                                    })
                                           }
                                    }

                                    });

                                }).catch(async (e) => {
                                    console.log(e);
                                    setError()
                                })
                        },
           flow: "auth-code",
           onError: (error) => console.log('Login Failed:', error)
       });

   const clickContinueEmail = () => {

    amplitude.getInstance().logEvent('button_onboarding_create_account_create_account_email_tapped');
    switchOption("email");
   }

    const setError = () => {
        amplitude.getInstance().logEvent('button_onboarding_create_account_account_created_failed')
        setWaitingPopup(false)
        setClosedPopup(false)
        setDisableButton(false)
    }

    const signUpPaymentEvent = () => {
        console.log("signUpPaymentEvent " + localStorage.getItem('fullName'));
        let userName = localStorage.getItem('fullName');
       // check inputFullName
         // send names to facebook capi event for higher event score
         try{
          const eventSystems = ['Subscribe', 'Purchase', 'StartTrial']
           if(userName.includes(" ")){
                            // split to first name and last name
                            const names = userName.split(' ');
                            const firstName = names[0];
                            const lastName = names[1];

                            eventSystems.map(action => {
                                         updatePaymentEvent(action, firstName, lastName, domain, localStorage.getItem('fb_id'))
                            })

           } else{
                   eventSystems.map(action => {
                                    updatePaymentEvent(action, userName, null, domain, localStorage.getItem('fb_id'))
                  })
          }
         } catch(e){
         console.log(e);
         }
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>{strings.registration.title}</h1>
            <p className={styles.subtitle}>{strings.registration.subtitle}</p>
            <div className={styles.formContainer}>
{/*                   <AppleSignin */}
{/*                       */}
{/*                       authOptions={{ */}
{/*                         */}
{/*                         clientId: '1.app.everdance', */}
{/*                         audience: 'com.everdance.everdance', */}
{/*                         */}
{/*                         scope: 'email name', */}
{/*                          */}
{/*                         redirectURI: 'https://test2.everdance.org', */}
{/*                         */}
{/*                         state: 'state', */}
{/*                          */}
{/*                         nonce: 'nonce', */}
{/*                          */}
{/*                         usePopup: true, */}
{/*                       }} // REQUIRED */}
{/*                       */}
{/*                       uiType="dark" */}
{/*                        */}
{/*                       className="apple-auth-btn" */}
{/*                        */}
{/*                       noDefaultStyle={false} */}
{/*                      */}
{/*                       buttonExtraChildren="Continue with Apple" */}
{/*                       */}
{/*                        */}
{/*                       onSuccess={(response) => appleResponse(response)} // default = undefined */}
{/*                       */}
{/*                       onError={(error) => console.error(error)} // default = undefined */}
{/*                        */}
{/*                       skipScript={false} // default = undefined */}
{/*                        */}
{/*                       iconProp={{ style: { marginTop: '10px' } }} // default = undefined */}
{/*                        */}
{/*                       render={(props) => <button {...props} className={`${styles.optionContainer}`} onClick={props.onClick} > */}
{/*                                          <img className={styles.buttonImage} src="/images/registration/apple.png" /> */}
{/*                                          <span className={styles.optionText}> {strings.registration.withApple}</span> */}
{/*                                         </button>} */}
{/*                     /> */}
                 <FacebookProvider appId="499580667556769">
                 <button className={`${styles.optionContainer}`} onClick={handleFacebookLogin} disabled={isLoading} >
                                    <img className={styles.buttonImage} src="/images/registration/facebook.png" />
                                    <span className={styles.optionText}> {strings.registration.withFB}</span>
                 </button>
                 </FacebookProvider>

                 <GoogleOAuthProvider clientId='1023626244689-jos22lkfke1tvm6qb959tpm3eqrb39a1.apps.googleusercontent.com'>
                     <React.StrictMode>
                       <button className={`${styles.optionContainer}`} onClick={googleLogin} >
                                                        <img className={styles.buttonImage} src="/images/registration/gmail.png" />
                                                        <span className={styles.optionText}> {strings.registration.withGmail}</span>
                     </button>
                     </React.StrictMode>

                   </GoogleOAuthProvider>

                   <button className={`${styles.optionContainer}`} onClick={clickContinueEmail} >
                                                         <img className={styles.buttonImage} src="/images/registration/email.png" />
                                                         <span className={styles.optionText}> {strings.registration.withEmail}</span>
                   </button>

                <div className={styles.errorContainer}>
                    { errorMessage && (<p className={`${styles.defaultMessage} ${styles.errorMessage}`}>{errorMessage}</p>) }
                    { infoMessage && (<p className={`${styles.defaultMessage} ${styles.infoMessage}`}>{infoMessage}</p>) }
                </div>
            </div>

            <div className={`${styles.agreements} flex-column`}>
                <span>{strings.registration.bySigning}</span>
                <div className={`${styles.agreementLinks} flex-row`}>
                    <a href={'https://everdance.app/terms'}>Terms&Service</a>
                    <span>. {strings.registration.view}</span>
                    <a href={'https://everdance.app/policy'}>Privacy Policy</a>
                    <span>.</span>
                </div>
            </div>
        </div>
    )
}

export default RegistrationFormSelect;